<template>
  <div id="sales-reports-view" class="sales-reports-container">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" title="RechargeSummaryReport">
      <template v-slot:right-end>
        <vs-button @click="viewAllReportData()"
                   class="mt-3 py-4 px-3 ml-4" color="primary">
          {{ viewAll ? $t('ShowLess') : $t('ViewAll') }}
        </vs-button>
      </template>
    </breadcrumb-base>

    <transition name="fade">
      <div class="vx-row">

        <div class="vx-col md:w-1/5 mt-4">
          <vx-card>

            <vs-button v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"
                       @click="clearFilterData">{{$t('ClearAllFilters').toUpperCase()}}
            </vs-button>
            <div class="py-2"></div>

            <!--            From TO Date Filters -->
            <!--            <h4>{{ $t('FromDate') | title }}</h4>-->
            <div class="py-2"></div>
            <label style="font-size: 12px; font-weight: bold">{{ $t('FromDate') }}
              <span
                @click="startDate = null;endDate = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <datepicker :placeholder="$t('FromDate')"
                        :disabled-dates="{from: new Date()}"
                        v-model="startDate" v-on:select="filterChanged"></datepicker>
            <div class="py-2"></div>
            <!--            <h4>{{ $t('ToDate') | title }}</h4>-->
            <div class="py-2"></div>
            <label style="font-size: 12px; font-weight: bold">{{ $t('ToDate') }}
              <span
                @click="startDate = null;endDate = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <datepicker :placeholder="$t('ToDate')"
                        v-model="endDate" :disabled-dates="disabledDates"></datepicker>
            <div class="py-2"></div>

            <label style="font-size: 12px; font-weight: bold">{{ $t('TransactionMode') }}
              <span
                @click="selectedPaymentMode = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <vs-select v-model="selectedPaymentMode"
                       autocomplete
                       class="select-large sm:pb-6  w-full">
              <vs-select-item :key="index" :value="item" :text="item | title | uppercase"
                              v-for="(item,index) in paymentData"/>
            </vs-select>

            <div class="py-2"></div>

            <vs-button v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"
                       @click="filterChanged">{{ $t('Filter').toUpperCase() }}
            </vs-button>
            <div class="py-2"></div>
            <!--            <vs-button v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"-->
            <!--                       @click="clearFilterData">{{ $t('ClearAllFilters').toUpperCase() }}-->
            <!--            </vs-button>-->
          </vx-card>
        </div>
        <div class="vx-col vx-col md:w-4/5">

          <!--    Error List State-->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
                <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
                     class="mx-auto mb-4 max-w-full">
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('ErrorData')}}</h1>
              </div>
            </div>
          </transition>
          <!--    Empty List State -->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="walletHistory.length === 0 && !errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                     class="mx-auto mb-4 max-w-full">-->
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('NoData')}}</h1>
              </div>
            </div>
          </transition>

          <vs-table-modified v-show="walletHistory.length > 0 && !errorFetching" ref="table"
                             :max-items="20"
                             :data="walletHistory"
          >
            <template slot="header">
              <table class="header-table" style="width: auto;" v-if="walletHistoryRaw">
                <thead>
                <th class="text-center">
                  {{ $t('TotalAmount') }}
                </th>
                </thead>
                <tbody>
                <tr>
                  <td class="text-center">
                    <span>{{ walletHistoryRaw.total_value | germanNumberFormat4 }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </template>

            <template slot="thead">

              <vs-th >{{ $t('Date') }}</vs-th>
              <vs-th >{{ $t('TransactionMode') }}</vs-th>
              <vs-th >{{ $t('Amount') }}</vs-th>
              <vs-th >{{ $t('OldCredit') }}</vs-th>
              <vs-th >{{ $t('NewCredit') }}</vs-th>
              <vs-th >{{ $t('OldBalance') }}</vs-th>
              <vs-th >{{ $t('NewBalance') }}</vs-th>
              <vs-th >{{ $t('Remarks') }}</vs-th>
            </template>
            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <p class="font-medium truncate">{{ tr.updated | dateFormat }}</p>
                </vs-td>
                <vs-td>
                  <p class="font-medium truncate">{{ tr.transaction_mode | title | uppercase }}</p>
                </vs-td>
                <vs-td>
                  <p class="font-medium truncate table-value-item" >{{ tr.value | germanNumberFormat }}</p>
                </vs-td>
                <vs-td>
                  <p class="font-medium truncate" >{{ tr.to_standing_credit | germanNumberFormat }}</p>
                </vs-td>
                <vs-td>
                  <p class="font-medium truncate" >{{ tr.to_credit | germanNumberFormat }}</p>
                </vs-td>
                <vs-td>
                  <p class="font-medium truncate" v-if="activeUserInfo.balance_type == 0">{{ (tr.to_standing_balance) | germanNumberFormat }}</p>
                  <p class="font-medium truncate"  v-if="activeUserInfo.balance_type == 1">{{ (tr.to_standing_balance - tr.to_standing_credit) | germanNumberFormat }}</p>
                </vs-td>
                <vs-td>
                  <p class="font-medium truncate" v-if="activeUserInfo.balance_type == 0">{{ (tr.to_balance) | germanNumberFormat }}</p>
                  <p class="font-medium truncate" v-if="activeUserInfo.balance_type == 1">{{ (tr.to_balance - tr.to_credit) | germanNumberFormat }}</p>
                </vs-td>
                <vs-td>
                  <p class="font-medium" style="max-width: 250px;">{{ tr.remarks }}</p>
                </vs-td>
              </vs-tr>
              </tbody>
            </template>
            <template slot="footer">
            </template>

            <template slot="footer-left">
              <div class="flex">
                <vs-new-pagination :total="vTotalPages" v-model="cPage" v-on:change="listPageChanged">
                </vs-new-pagination>
              </div>
            </template>
          </vs-table-modified>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import VxModal from '@/layouts/components/custom/VxModal'

export default {
  name: 'RechargeSummaryReport',
  components: {
    Datepicker,
    VxModal,
  },
  data() {
    return {
      cPage: 1,
      errorFetching: false,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Recharge Summary Report', i18n: 'RechargeSummaryReport', active: true },
      ],
      filterSelectData: {},
      startDate: new Date(),
      endDate: new Date(),
      selectedPaymentMode: null,
      viewAll: false,
    }
  },
  computed: {
    disabledDates() {
      if (!this.startDate) {
        return {
          from: new Date(),}
      }
      return {
        to: this.startDate,
        from: new Date(),
      }
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    walletHistory() {
      return this.$store.state.customer.walletHistory
    },
    walletHistoryRaw() {
      return this.$store.state.customer.walletHistoryRaw
    },
    transactionTypesList() {
      if(this.walletHistoryRaw) return this.$store.state.customer.walletHistoryRaw.transaction_type;
      else return [];
    },
    vPage() {
      return this.$store.state.customer.vPage
    },
    vTotalPages() {
      return this.$store.state.customer.vTotalPages
    },
    paymentData() {
      let list = this.activeUserInfo.credit_transaction_type_modes;
      return list
        .sort(function (a, b) {
          return a < b ? -1 : 1;
        })
    },
  },
  methods: {

    getTransactionType(value) {
      if (value === 0) return 'Credit'
      if (value === 1) return 'Cash'
      return ''
    },
    listPageChanged(item) {
      this.fetchWalletHistory(this.cPage - 1)
    },

    viewAllReportData() {
      this.viewAll = !this.viewAll;

      this.filterChanged()
    },
    getFilters() {
      const filter = {
        'rs_report': 1
      }

      if (this.startDate) {
        filter.start_date = moment(this.startDate).format('yyyy-MM-DD')
      }
      if (this.endDate) {
        filter.end_date = moment(this.endDate).format('yyyy-MM-DD')
      }

      if (this.selectedPaymentMode) {
        filter.transaction_mode = this.selectedPaymentMode.toLowerCase()
      }
      if(this.viewAll) {
        filter['view_all'] = this.viewAll ? 1 : 0;
      }

      return filter
    },
    fetchWalletHistory(page = '0') {
      let payload  = this.getFilters();
      this.$vs.loading()
      this.$store.dispatch('customer/fetchCustomerWalletHistory', { page, payload })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    filterChanged() {
      this.fetchWalletHistory('0', this.getFilters())
    },
    clearFilterData() {
      this.filterSelectData = {}
      this.startDate = null
      this.endDate = null
      this.filterChanged()
    },
    clearFilter(key) {
      this.filterSelectData[key] = null
      this.filterChanged()
    },
  },
  created() {
    // this.fetchWalletHistory()
  },
}
</script>

<style lang="scss">
.header-table {
  width: auto;

  th {
    font-weight: normal;
    font-size: 14px;
    min-width: 200px;
  }

  td {
    font-weight: bold;
    font-size: 24px;
    color: rgba(var(--vs-primary), 1);
  }
}

#sales-reports-view {
  .vs-select--label {
    padding: 0;
  }

  .header-table {
    width: auto;

    th {
      font-weight: normal;
      font-size: 14px;
      min-width: 200px;
    }
    td {
      font-weight: bold;
      font-size: 24px;
      color: rgba(var(--vs-warning), 1);
    }
  }

.vs-con-table {

/*
  Below media-queries is fix for responsiveness of action buttons
  Note: If you change action buttons or layout of this page, Please remove below style
*/
@media (max-width: 689px) {
  .vs-table--search {
    margin-left: 0;
    max-width: unset;
    width: 100%;

  .vs-table--search-input {
    width: 100%;
  }
}
}

@media (max-width: 461px) {
  .items-per-page-handler {
    display: none;
  }
}

@media (max-width: 341px) {
  .products-list-btn-container {
    width: 100%;

  .dd-actions,
  .btn-add-new {
    width: 100%;
    margin-right: 0 !important;
  }
}
}

.product-name {
  max-width: 23rem;
}

.vs-table--header {
  display: flex;
  flex-wrap: wrap;
  margin-left: 1.5rem;
  margin-right: 1.5rem;

> span {
  display: flex;
  flex-grow: 1;
}

.vs-table--search {
  padding-top: 0;

.vs-table--search-input {
  padding: 0.9rem 2.5rem;
  font-size: 1rem;

& + i {
    left: 1rem;
  }

&:focus + i {
   left: 1rem;
 }
}
}
}

.vs-table {
  border-collapse: separate;
  border-spacing: 0 1.3rem;
  padding: 0 1rem;

tr {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

td {
  padding: 20px;

&:first-child {
   border-top-left-radius: .5rem;
   border-bottom-left-radius: .5rem;
 }

&:last-child {
   border-top-right-radius: .5rem;
   border-bottom-right-radius: .5rem;
 }
}

td.td-check {
  padding: 20px !important;
}
}
}

.vs-table--thead {
th {
  padding-top: 0;
  padding-bottom: 0;

.vs-table-text {
  text-transform: uppercase;
  font-weight: 600;
}
}

th.td-check {
  padding: 0 15px !important;
}

tr {
  background: none;
  box-shadow: none;
}
}

.vs-table--pagination {
  justify-content: center;
}
}
}
</style>
